import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="text-center">
          <div className="col-md-12">
            <img src="img/apply.jpg" className="img-fluid" alt="" />
          </div>
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>Apply Now</h2>
              <h3>A Streamlined Application Process</h3>
              <p >{props.data ? props.data.paragraph : "loading..."}</p>
              
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
